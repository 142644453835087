export const queryFragmentClusterEntries = {
  cluster_entries: thumbnailClusterEntry()
}

export const queryFragmentEventEntries = {
  event_entries: thumbnailEventEntry
}

export const queryFragmentVenueEntries = {
  venue_entries: thumbnailVenueEntry
}

export const queryFragmentParticipantEntries = {
  participant_entries: thumbnailParticipantEntry
}

export const queryFragmentNewsEntries = {
  news_entries: thumbnailNewsEntry
}
