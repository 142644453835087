<template>
  <ul
    v-if="openingTimes?.length"
    :class="$style.root"
  >
    <li
      v-for="entry in entries"
      :key="entry?.id"
    >
      {{ formatOpeningDays(entry?.days) }},

      <template
        v-for="(time, index) in entry?.times"
        :key="time?.id"
      >
        {{ formatOpeningTimes(time?.opening_time, time?.closing_time) }}{{ index < entry?.times?.length - 1 ? ', ' : '' }}
      </template>
    </li>
  </ul>
</template>

<script setup>
const { locale: currentLocale } = useI18n()
import weekDays from "i18n-week-days"

const props = defineProps({
  openingTimes: {
    type: Object
  }
})

const weekdayNames = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday']

const entries = computed(() => {
  const entries = props?.openingTimes?.map((entry) => {
    const days = entry?.days?.map((day) => {
      return {
        index: weekdayNames?.indexOf(day?.toLowerCase()?.trim()),
        value: day?.toLowerCase()?.trim(),
      }
    })?.sort((a, b) => a?.index - b?.index)

    return {
      ...entry,
      days: days,
      startIndex: days?.length > 0 ? days[0]?.index : null,
      endIndex: days?.length > 0 ? days[days?.length - 1]?.index : null
    }
  })

  const groupedEntries = Object.values(
    entries?.reduce((acc, currentValue) => {
      let groupKey = currentValue?.days;

      if (!acc[groupKey]) {
        acc[groupKey] = [];
      }

      acc[groupKey].push(currentValue);

      return acc;
    }, {})
  )?.filter(i => i?.length > 0)?.map((i) => {
    return {
      days: i[0].days,
      label: i[0].label,
      times: i?.map((entry) => {
        return {
          opening_time: entry?.opening_time,
          closing_time: entry?.closing_time
        }
      })?.sort((a, b) => a?.opening_time - b?.opening_time)?.sort((a, b) => a?.closing_time - b?.closing_time)
    }
  })?.flat()?.sort((a, b) => a?.startIndex - b?.startIndex)?.sort((a, b) => a?.endIndex - b?.endIndex)

  return groupedEntries
})

const formatOpeningDays = (days) => {
  if (!days?.length) return

  const groupedDays = Object.values(
    days?.reduce((acc, currentValue) => {
      let groupKey = currentValue?.index;

      const canBeGrouped = Object.values(acc)?.filter((group) => {
        return group[group?.length - 1]?.index === (currentValue?.index - 1)
      })

      if (!acc[groupKey] || canBeGrouped) {
        acc[groupKey] = [];
      }

      if (canBeGrouped?.length) {
        const index = Object.values(canBeGrouped)?.map(i => Object.values(i)[0]?.index)
        acc[index].push(currentValue);
      } else {
        acc[groupKey].push(currentValue);
      }

      return acc;
    }, {})
  )?.filter(i => i?.length > 0)

  return groupedDays?.map(group => {
    const localeDate = (index) => {
      return weekDays[currentLocale.value][index]
    }
    return group?.length > 1 ? `${localeDate(group[0]?.index)} – ${localeDate(group[group?.length - 1]?.index)}` : localeDate(group[0]?.index)
  })?.filter(Boolean)?.join(', ')
}

const formatOpeningTimes = (timeOpen, timeClose) => {
  if (!timeOpen && !timeClose) return
  const open = timeOpen?.split(':')?.slice(0, 2)?.join('.')
  const close = timeClose?.split(':')?.slice(0, 2)?.join('.')
  if (timeOpen && timeClose) {
    return `${open} – ${close}`
  } else if (timeOpen) {
    return open
  } else if (timeClose) {
    return close
  }
}
</script>

<style module>
  .root {
    composes: reset-list from global;
  }

  .list {
    composes: reset-list from global;
  }
</style>
