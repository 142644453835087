import { defineStore } from 'pinia'

export const useMapStore = defineStore('map', {
  state: () => ({
    zoom: {
      cluster: 11,
      city: 12.5,
      venue: 13,
      route: 13,
      focusWeek: 14,
      eventLocation: 15,
    },
    showPill: {
      cluster: 9,
      city: 12.5,
      venue: 13,
      route: 13,
      focusWeek: 14,
      eventLocation: 15,
    }
  })
})
